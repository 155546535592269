* {
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

hr {
  border: 0;
  border-top: 1px solid #eaeaea;
  margin: 1.5rem 0;
}
